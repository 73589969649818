<template>
	<div class="box">
		<!-- 遮罩层 -->
		<div class="zhezhao" @click="zhezhaoclose" v-show="zhezhaoflag"></div>
		<!-- renli -->
		<div class="zh-box renren" v-show="flag">
			<div class="renedit"><span>修改</span></div>
			<div class="ren-box">
				<div class="ren-item">
					<label for="zong">工厂总人数</label>
					<input type="text" id="zong" v-model="r_zong" :placeholder="peoplezy.zong" />
				</div>
				<div class="ren-item">
					<label for="zong">在岗</label>
					<input type="text" id="zong" v-model="r_zaigang" :placeholder="peoplezy.zaigang" />
				</div>
				<div class="ren-item">
					<label for="zong">今日值班</label>
					<input type="text" id="zong" v-model="r_zhiban" :placeholder="peoplezy.zhiban" />
				</div>
				<div class="ren-item">
					<label for="zong">今日休班</label>
					<input type="text" id="zong" v-model="r_xiuban" :placeholder="peoplezy.xiuban" />
				</div>
			</div>
			<div class="ren-btn">
				<div @click.stop="zhezhaoclose">取消</div>
				<div @click="qredit">确认修改</div>
			</div>
		</div>
		<!-- 头部 -->
		<headers></headers>
		<!-- 内容 -->
		<div class="con">
			<div class="con-left">
				<div class="con-left-top">
					<div class="con-l">
						<div class="con-l-title" @click="todetail('productionline')">
							<span>生产线数据</span>
							<img src="../../assets/images/jin.png" alt="" />
						</div>
						<div class="scx">
							<div class="rkclsl">
								<progress :value="leijinum" :max="leijinum"></progress>
								<div>
									<span>机制砂数量（累计）</span>
									<p>
										{{ leijinum }}
										<span>T</span>
									</p>
								</div>
							</div>
							<div class="clscsl rkclsl">
								<progress :value="config2.data[0].value" :max="leijinum"></progress>
								<div>
									<span>今日生产数量</span>
									<p>
										{{ config2.data[0].value }}
										<span>T</span>
									</p>
								</div>
								<div class="xl">
									<span>实时生产效率</span>
									<p>
										{{ rixlv }}
										<span>T/H</span>
									</p>
								</div>
							</div>
							<div class="clscsl rkclsl">
								<progress :value="zhoudata" :max="leijinum"></progress>
								<div>
									<span>本周生产数量</span>
									<p>
										{{ zhoudata }}
										<span>T</span>
									</p>
								</div>
							</div>
							<div class="clscsl rkclsl">
								<progress :value="crk3.data[0].value" :max="leijinum"></progress>
								<div>
									<span>本月生产数量</span>
									<p>
										{{ crk3.data[0].value }}
										<span>T</span>
									</p>
								</div>
							</div>
						</div>
						<!-- 出入库数据 -->
						<div class="con-l-title crk-title">
							<div class=" crk-title-l" @click="todetail('receiptIssue')">
								<span>出入库数据</span>
								<img src="../../assets/images/jin.png" alt="" />
							</div>
							<div class="crk-tab">
								<span :class="crkindex === 0 ? 'on' : ''" @click="crktab(0)">今日</span>
								<span :class="crkindex === 1 ? 'on' : ''" @click="crktab(1)">本月</span>
								<span :class="crkindex === 2 ? 'on' : ''" @click="crktab(2)">今年</span>
							</div>
						</div>
						<div class="crk-tabbox" v-if="crkindex === 0">
							<div class="crkdata">
								<div class="crkdata-top">
									<div class="crkdata-top-item">
										<span>今日生产总量</span>
										<p>
											{{ config2.data[0].value }}
											<span>T</span>
										</p>
									</div>
									<div class="crkdata-top-item">
										<span>剩余库存</span>
										<p>
											{{ config2.data[2].value }}
											<span>T</span>
										</p>
									</div>
								</div>
							</div>
							<!-- 今日成品料出场 -->
							<div class="jrcpl">
								<div id="pieone"></div>
								<!-- <dv-active-ring-chart :config="config2" style="width:280px;height:280px" /> -->
								<div class="jrcpl-list">
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div></div>
												<span>{{ config2.data[0].name }}</span>
											</div>
											<p>
												{{ config2.data[0].value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div class="cpl2"></div>
												<span>{{ config2.data[1].name }}</span>
											</div>
											<p>
												{{ config2.data[1].value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div class="cpl3"></div>
												<span>{{ config2.data[2].name }}</span>
											</div>
											<p>
												{{ config2.data[2].value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="crk-tabbox" v-if="crkindex === 1">
							<div class="crkdata">
								<div class="crkdata-top">
									<div class="crkdata-top-item">
										<span>本月生产总量</span>
										<p>
											{{ crk2.data[0].value }}
											<span>T</span>
										</p>
									</div>
									<div class="crkdata-top-item">
										<span>剩余库存</span>
										<p>
											{{ crk2.data[2].value }}
											<span>T</span>
										</p>
									</div>
								</div>
							</div>
							<!-- 今日成品料出场 -->
							<div class="jrcpl">
								<div id="pietwo"></div>
								<!-- <dv-active-ring-chart :config="crk2" style="width:280px;height:280px" /> -->
								<div class="jrcpl-list">
									<div class="jrcpl-list-item" v-for="(crk2, index) in crk2.data" :key="index">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div :class="'kuai' + index"></div>
												<span>{{ crk2.name }}</span>
											</div>
											<p>
												{{ crk2.value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="crk-tabbox" v-if="crkindex === 2">
							<div class="crkdata">
								<div class="crkdata-top">
									<div class="crkdata-top-item">
										<span>今年生产总量</span>
										<p>
											{{ crk3.data[0].value }}
											<span>T</span>
										</p>
									</div>
									<div class="crkdata-top-item">
										<span>剩余库存</span>
										<p>
											{{ crk2.data[2].value }}
											<span>T</span>
										</p>
									</div>
								</div>
							</div>
							<!-- 今日成品料出场 -->
							<div class="jrcpl">
								<div id="piethree"></div>
								<!-- <dv-active-ring-chart :config="crk3" style="width:280px;height:280px" /> -->
								<div class="jrcpl-list">
									<div class="jrcpl-list-item" v-for="(crk2, index) in crk3.data" :key="index">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div :class="'kuai' + index"></div>
												<span>{{ crk2.name }}</span>
											</div>
											<p>
												{{ crk2.value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 中间部分 -->
					<div class="con-center2">
						<div class="con-center-main">
							<div class="con-center-main-top">
								<div class="con-center-tab">
									<span :class="tabIndex == 0 ? 'jk-select' : ''" @click="tab(0)">实时监控</span>
									<!-- <span :class="tabIndex == 1 ? 'jk-select' : ''" @click="tab(1)">录像回放</span> -->
								</div>
								<img src="../../assets/images/cha.png" @click="back" />
							</div>
							<section v-if="tabIndex === 0">
								<div class="On-access">
									<div class="con-center-sx-top">
										<div class="jklb" @click="jkshow">
											<img src="../../assets/images/list.png" alt="" />
											<span>监控列表</span>
										</div>
									</div>
									<div class="con-center-sx-btm" v-show="jklistflag">
										<div :class="jkindex == item.id ? 'con-center-sx-jk active' : 'con-center-sx-jk '"
											v-for="item in videoList" :key="item.id" @click="jktab(item.id)">
											<p>
												<img src="../../assets/images/Frame.png" alt=""
													v-if="jkindex == item.id" />
												<img src="../../assets/images/dw.png" alt="" v-else />
												<span>{{ item.name }}</span>
											</p>
											<img src="../../assets/images/duigou.png" alt=""
												v-show="jkindex == item.id" />
										</div>
									</div>
								</div>
								<div class="jkhm">
									<live-player class="sp" reconnection live muted autoplay has-audio
										:video-url="video.url" style="width: 100%;height: 580px;"
										@error="restartPlayer" />
									<div class="jkhm-top">
										<p></p>
										<span @click="jkedit(video)">编辑</span>
										<span>{{ video.name }}</span>
									</div>
								</div>
							</section>
							<section v-if="tabIndex === 1">
								<div class="con-center-sx">
									<div class="con-center-sx-l">
										<div class="con-center-sx-top">
											<div class="jklb" @click="jkshow">
												<img src="../../assets/images/list.png" alt="" />
												<span>监控列表</span>
											</div>
										</div>
										<div class="con-center-sx-btm" v-show="jklistflag">
											<div :class="jklxinx == item.id ? 'con-center-sx-jk active' : 'con-center-sx-jk'"
												v-for="item in videoList" :key="item.id" @click="jklx(item.id)">
												<p>
													<img src="../../assets/images/Frame.png" alt=""
														v-if="jklxinx == item.id" />
													<img src="../../assets/images/dw.png" alt="" v-else />
													<span>{{ item.name }}</span>
												</p>
												<img src="../../assets/images/duigou.png" alt=""
													v-show="jklxinx == item.id" />
											</div>
										</div>
									</div>
									<div class="con-center-sx-r">
										<div class="lx-rq-search">
											<div class="lx-rq">
												<el-date-picker v-model="time" type="datetimerange"
													value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
													start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
											</div>
											<div class="lx-sou" @click="soulux">搜索</div>
										</div>
									</div>
								</div>
								<div class="jkhm">
									<live-player v-if="luxflag == false" class="sp" live muted autoplay reconnection
										has-audio :video-url="video.url" style="width: 100%;height: 580px;"
										@error="restartPlayer2" />
									<live-player v-if="luxflag == true" class="sp" live reconnection muted autoplay
										has-audio :video-url="videourl" style="width: 100%;height: 580px;"
										@error="restartPlayer3" />
									<div class="jkhm-top">
										<p></p>
										<span @click="jkedit(video)">编辑</span>
										<span>{{ video.name }}</span>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
				<!-- 地磅实时数据 -->
				<div class="con-left-buttom">
					<div class="d-flex a-center clb_tab">
						<span class="son" :class="dbindex === 0 ? 'on' : ''" @click="todetail('Weighbridge?type=0')"
							@mouseenter="dbindex=0">成品纱实时数据</span>
						<span class="son" :class="dbindex === 1 ? 'on' : ''" @click="todetail('Weighbridge?type=1')"
							@mouseenter="dbindex=1">毛砂实时数据</span>
					</div>
					<block v-if="dbindex==0">
						<div class="db-t">
							<div v-for="(name, index) in header" :key="index" class="db-t-h">{{ name }}</div>
						</div>
						<div v-for="item in dblist" :key="item.id" class="db-b">
							<div class="">
								<span>{{ item.xuhao }}</span>
								<span>{{ item.huoming }}</span>
								<span>{{ item.guige }}</span>
								<span v-if="item.type == 2">出厂</span>
								<span v-if="item.type == 1">入厂</span>
								<span>{{ item.chehao }}</span>
								<span>{{item.sh_unit}}</span>
								<span>{{ item.maozhong }}T</span>
								<span>{{ item.pizhong }}T</span>
								<span>{{ item.jingzhong }}T</span>
								<span>{{ item.gengxinren }}</span>
								<span>{{ item.fguobang }}</span>
								<span>{{ item.sguobang }}</span>
							</div>
						</div>
					</block>
					<block v-else>
						<div class="db-t">
							<div v-for="(name, index) in header2" :key="index" class="db-t-h">{{ name }}</div>
						</div>
						<div v-for="item in dblist2" :key="item.id" class="db-b">
							<div class="">
								<span>{{ item.xuhao }}</span>
								<span>{{ item.huoming }}</span>
								<span>{{ item.guige }}</span>
								<span v-if="item.type == 2">出厂</span>
								<span v-if="item.type == 1">入厂</span>
								<span>{{ item.chehao }}</span>
								<span>{{ item.maozhong }}T</span>
								<span>{{ item.pizhong }}T</span>
								<span>{{ item.jingzhong }}T</span>
								<span>{{ item.gengxinren }}</span>
								<span>{{ item.fguobang }}</span>
								<span>{{ item.sguobang }}</span>
							</div>
						</div>
					</block>
				</div>
			</div>
			<div class="con-r">
				<div class="con-l-title">
					<span>安全警告</span>
					<img src="../../assets/images/jin.png" alt="" />
				</div>
				<!-- 安全警告 -->
				<div class="aqjg">
					<img src="../../assets/images/redyuj.png" alt="" />
					<img src="../../assets/images/y-yuj.png" alt="" />
					<img src="../../assets/images/g-yj.png" alt="" />
				</div>
				<!-- 人力资源 -->
				<div class="con-l-title rlzy-title">
					<span>人力资源</span>
					<img src="../../assets/images/jin.png" alt="" />
				</div>
				<div class="rlzy-list">
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<img src="../../assets/images/Group 27.png" alt="" />
							<span>工厂总人数</span>
						</div>
						<p>
							{{ peoplezy.zong }}
							<span>人</span>
						</p>
					</div>
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<div></div>
							<span>在岗</span>
						</div>
						<p>
							{{ peoplezy.zaigang }}
							<span>人</span>
						</p>
					</div>
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<div></div>
							<span>今日值班</span>
						</div>
						<p>
							{{ peoplezy.zhiban }}
							<span>人</span>
						</p>
					</div>
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<div></div>
							<span>今日休班</span>
						</div>
						<p>
							{{ peoplezy.xiuban }}
							<span>人</span>
						</p>
					</div>
				</div>
				<!-- 电力资源 -->
				<div class="con-l-title rlzy-title " @click="todetail('power')">
					<span>电力资源</span>
					<img src="../../assets/images/jin.png" alt="" />
				</div>
				<div class="dlzy">
					<div class="dlzy-top">
						<div class="dlzy-item">
							<img src="../../assets/images/Group 2.png" alt="" />
							<div>
								<p>
									{{ daydian }}
									<span>kw.h</span>
								</p>
								<span>今日耗电量</span>
							</div>
						</div>
						<div class="dlzy-item">
							<img src="../../assets/images/Group 2.png" alt="" />
							<div>
								<p>
									{{ yuedian }}
									<span>kw.h</span>
								</p>
								<span>今日耗电量</span>
							</div>
						</div>
					</div>
					<div id="line"></div>
				</div>
			</div>
		</div>
		<el-dialog title="修改监控名称" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="editname">
				<span>监控名称:</span>
				<input type="text" v-model="name" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="qdedit(video.id)">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import * as echarts from 'echarts';
	import LivePlayer from '@liveqing/liveplayer';
	const that = this;
	const config2 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生成总量',
				value: 100
			},
			{
				name: '销售总量',
				value: 80
			},
			{
				name: '剩余库存',
				value: 20
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	const crk2 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生成总量',
				value: 300
			},
			{
				name: '销售总量',
				value: 200
			},
			{
				name: '剩余库存',
				value: 100
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	const crk3 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生成总量',
				value: 3600
			},
			{
				name: '销售总量',
				value: 3300
			},
			{
				name: '剩余库存',
				value: 300
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	//
	import headers from '../../components/headers.vue';
	export default {
		data() {
			return {
				header: ['序号', '货名', '规格', '出入标识', '车号', '毛重', '皮重', '磅单毛重', '水重', '地磅净重', '榜单净重', '过磅员', '首次过磅时间',
					'二次过磅时间'
				],
				header2: ['序号', '货名', '规格', '出入标识', '车号', '毛重', '皮重', '地磅净重', '过磅员', '首次过磅时间',
					'二次过磅时间'
				],
				zshengyu: '', // 总剩余
				ridata: [], //生产数据日
				yuedata: [], //生产数据yue
				niandata: [], //生产数据nian
				zhoudata: [], //生产数据zhou
				leijinum: '', //累计生产数量
				rixlv: '', //日生产效率
				peoplezy: {}, //人力资源
				daydian: '',
				yuedian: '',
				zhoudate: {},
				zhoudian: {},
				r_zong: '',
				r_zaigang: '',
				r_zhiban: '',
				r_xiuban: '',
				dialogVisible: false,
				config2,
				crk2,
				crk3,
				flag: false,
				zhezhaoflag: false, //遮罩层
				jklistflag: false, //监控列表开关
				tabIndex: 0, //jiankong
				crkindex: 0, //churuku
				timeflag: false, //时间
				time: '',
				jkindex: 0,
				jklxinx: 0,
				bgindex: '',
				dblist: [],
				dblist2: [],
				search1: '', //搜索
				search2: '', //sousuo
				id: '',
				videoList: [],
				video: {},
				video2: {},
				name2: '',
				luxflag: false,
				videourl: '',
				name: '',
				dbindex: 0,

			};
		},
		components: {
			headers,
			LivePlayer
		},
		created() {
			this.bgindex = this.$route.query.index || null;
			this.getdblist();
			this.getrl();
			let timer = setInterval(() => {
				this.getdblist();
			}, 30000);

			const date = new Date();
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			this.time = year + '-' + month + '-' + day;
			const y_time = year + '-' + month;
			this.getpidaidata(y_time, year);
			this.getdian(y_time, year);

			// const h = date.getHours()
			// const m = date.getMinutes()
			// const s = date.getSeconds()
			// console.log(year,month,day,h,m,s)
		},
		mounted() {
			this.id = this.$route.query.id;
			this.jkindex = this.$route.query.id;
			this.jklxinx = this.$route.query.id;
			this.getshexiang();
			// 电力资源折线图
			this.getline();
		},
		methods: {
			// 人力资源
			editrenshow() {
				this.zhezhaoflag = true;
				// this.zh = true;
				this.flag = true;
			},
			xg() {},
			async xgdibang(item, a) {
				let data = {
					d_id: item.id,
					shuizhong: item.shuizhong,
					bdmz: item.bdmz,
					bdjz: item.bdjz
				}
				let that = this
				const res = await this.$postToken("datas/update-dibang", data)
				if (res.data.code == 200) {
					that.getdblist()
				} else {
					this.$message({
						message: res.data.msg,
						type: 'error'
					});
				}
			},
			async qredit() {
				const res = await this.$postToken('datas/renli2', {
					zong: this.r_zong,
					zaigang: this.r_zaigang,
					zhiban: this.r_zhiban,
					xiuban: this.r_xiuban
				});
				if (res.data.code == 200) {
					this.getrl();
					this.flag = false;
					this.zhezhaoflag = false;
				} else {
					this.flag = false;
					this.zhezhaoflag = false;
				}
			},
			async getrl() {
				const res = await this.$postToken('datas/renli ');
				if (res.data.code == 200) {
					console.log(res.data.result.day);
					this.peoplezy = res.data.result.day;
					this.r_zong = this.peoplezy.zong;
					this.r_zaigang = this.peoplezy.zaigang;
					this.r_zhiban = this.peoplezy.zhiban;
					this.r_xiuban = this.peoplezy.xiuban;
				}
			},

			pie1() {
				let that = this;
				var chartDom2 = document.getElementById('pieone');
				var myChart2 = echarts.init(chartDom2);
				var option;
				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false
					},
					color: ['#159aff', '#d0deee', '#66e1df'],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},

						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
							value: that.ridata.shengchan
						}, {
							value: that.ridata.xiaoshou
						}, {
							value: that.zshengyu
						}]
					}]
				};

				option && myChart2.setOption(option);
			},
			pie2() {
				let that = this;
				var chartDom3 = document.getElementById('pietwo');
				var myChart3 = echarts.init(chartDom3);
				var option;
				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false
					},
					color: ['#159aff', '#d0deee', '#66e1df'],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
							value: that.yuedata.shengchan
						}, {
							value: that.yuedata.xiaoshou
						}, {
							value: that.zshengyu
						}]
					}]
				};
				option && myChart3.setOption(option);
			},
			pie3() {
				let that = this;
				var chartDom = document.getElementById('piethree');
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false
					},
					color: ['#159aff', '#d0deee', '#66e1df'],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						name: 'Access From',
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
							value: that.niandata.shengchan
						}, {
							value: that.niandata.xiaoshou
						}, {
							value: that.zshengyu
						}]
					}]
				};
				option && myChart.setOption(option);
			},
			// 皮带秤
			async getpidaidata(a, b) {
				const res = await this.$postToken('datas/pidai-datas', {
					r_time: this.time,
					y_time: a,
					n_time: b
				});
				if (res.data.code == 200) {
					this.rixlv = Number(res.data.result.ri.xiaolv).toFixed(2);
					this.zshengyu = Number(res.data.result.zongshengyu).toFixed(2);
					config2.data[0].value = Number(res.data.result.ri.shengchan).toFixed(2);
					config2.data[1].value = Number(res.data.result.ri.xiaoshou).toFixed(2);
					config2.data[2].value = Number(res.data.result.zongshengyu).toFixed(2);

					crk2.data[0].value = Number(res.data.result.yue.shengchan).toFixed(2);
					crk2.data[1].value = Number(res.data.result.yue.xiaoshou).toFixed(2);
					crk2.data[2].value = Number(res.data.result.zongshengyu).toFixed(2);
					crk3.data[0].value = Number(res.data.result.nian.shengchan).toFixed(2);
					crk3.data[1].value = Number(res.data.result.nian.xiaoshou).toFixed(2);
					crk3.data[2].value = Number(res.data.result.zongshengyu).toFixed(2);
					this.ridata = res.data.result.ri;
					this.yuedata = res.data.result.yue;
					this.niandata = res.data.result.nian;
					this.zhoudata = Number(res.data.result.zhou.shengchan).toFixed(2);
					this.leijinum = res.data.result.leiji;
					this.pie1();
					this.pie2();
					this.pie3();
					this.$forceUpdate();
				}
			},

			// 电力
			async getdian(a, b) {
				const res = await this.$postToken('datas/dianli', {
					day: this.time,
					month: a,
					year: b
				});
				if (res.data.code == 200) {
					this.daydian = Number(res.data.result.day_dian2).toFixed(2);
					this.yuedian = Number(res.data.result.yue_dian2).toFixed(2);
					let zhou = res.data.result.zhou_data;
					this.zhoudate = zhou.map(item => {
						return item.date;
					});
					this.zhoudian = zhou.map(item => {
						return item.data;
					});
					this.getline(this.zhoudate, this.zhoudian);
					console.log();
				}
			},
			// 电力资源折线图
			getline(a, b) {
				var chartDom = document.getElementById('line');
				var myChart = echarts.init(chartDom);
				var option;
				option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: a
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: b,
						type: 'line',
						areaStyle: {}
					}]
				};
				option && myChart.setOption(option);
			},
			// 获取地磅数据列表
			async getdblist() {
				let timer2;
				let res = await this.$postToken('datas/dibang-list', {
					time: '',
					start_time: '',
					end_time: '',
					page_index: 1,
					page_size: 4
				});
				if (res.data.code == 200) {
					this.dblist = res.data.result.list;
				}

				let res2 = await this.$postToken('datas/maosha-list', {
					time: '',
					start_time: '',
					end_time: '',
					page_index: 1,
					page_size: 4
				});
				if (res2.data.code == 200) {
					this.dblist2 = res2.data.result.list;
				}

			},
			async soulux() {
				let s_time =
					this.time.join(',').slice(0, 4) +
					this.time.join(',').slice(5, 7) +
					this.time.join(',').slice(8, 10) +
					this.time.join(',').slice(11, 13) +
					this.time.join(',').slice(14, 16) +
					this.time.join(',').slice(17, 19);
				let e_time =
					this.time.join(',').slice(20, 24) +
					this.time.join(',').slice(25, 27) +
					this.time.join(',').slice(28, 30) +
					this.time.join(',').slice(31, 33) +
					this.time.join(',').slice(34, 36) +
					this.time.join(',').slice(37, 39);
				let res = await this.$postToken('datas/shexiang-his', {
					s_time: s_time,
					e_time: e_time,
					s_id: this.id
				});
				console.log(res);
				if (res.data.code == 200) {
					this.luxflag = true;
					this.videourl = res.data.result.url;
				}
			},
			// 监控列表显示
			jkshow() {
				this.jklistflag = true;
				this.zhezhaoflag = true;
			},
			zhezhaoclose() {
				this.jklistflag = false;
				this.zhezhaoflag = false;
				this.flag = false;
			},
			// 获取监控列表
			async getshexiang(e) {
				let res = await this.$postToken('datas/shexiang-data', {
					search_name: e || '',
					page_index: 1,
					page_size: 50
				});
				if (res.data.code == 200) {
					this.videoList = res.data.result.list;
					this.videoList.map(item => {
						if (item.id == this.id) {
							this.video = item;
							console.log(item);
							this.name = item.name;
						}
					});
				}
			},
			jkedit(e) {
				this.dialogVisible = true;
			},
			async qdedit(e) {
				this.dialogVisible = false;
				let res = await this.$postToken('datas/update-shexiangdata', {
					s_id: e,
					name: this.name
				});
				if (res.data.code == 200) {
					this.getshexiang();
					this.$message({
						message: res.data.msg,
						type: 'success'
					});
				}
			},
			handleClose() {
				this.dialogVisible = false;
			},
			getSearch1() {
				this.getshexiang(this.search1);
				console.log();
			},
			getSearch2() {
				this.getshexiang(this.search2);
				console.log(this.search2);
			},
			tab(e) {
				this.tabIndex = e;
			},
			// 监控选择
			jktab(e) {
				this.jklxinx = e;
				this.jkindex = e;
				this.id = e;
				this.luxflag = false;
				this.getshexiang();
			},
			// 监控录像选择
			jklx(e) {
				this.jklxinx = e;
				this.jkindex = e;
				this.luxflag = false;
				this.id = e;
				this.getshexiang();
			},
			// 返回上一级
			back() {
				this.$router.back();
			},
			todetail(url) {
				this.$router.push({
					path: '/' + url
				});
			},
			crktab(e) {
				this.crkindex = e;
			},
			// 获取时间
			gettime(data) {
				this.time = data;
			},
			timeshow() {
				this.timeflag = !this.timeflag;
			},
			// 播放
			// sp error
			restartPlayer() {},
			restartPlayer2() {},
			restartPlayer3() {}
		}
	};
</script>
<style>
	.box {
		position: relative;
	}

	.user-zh {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
	}

	.user-name img {
		display: block;
		width: 30px;
		height: 30px;
	}

	.user-name {
		display: flex;
		align-items: center;
	}

	.out-login {
		width: 94px;
		height: 30px;
		line-height: 30px;
		background-color: #022042;
		border-radius: 4px 4px 4px 4px;
		color: #159aff;
		text-align: center;
		margin-top: 7px;
	}

	.user-name span {
		font-size: 14px;
		color: #159aff;
	}

	.header-r span {
		font-size: 32px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		padding-right: 3%;
		color: #ffffff;
	}

	/* 内容 */
	.con {
		display: flex;
		justify-content: space-between;
	}

	.con-left {
		width: 76%;
	}

	.con-left .con-left-top {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	/* 内容左侧 */
	.con-l {
		margin-left: 36px;
		width: 400px;
		height: 100%;
	}

	.con-l-title {
		height: 40px;
		/*  */
		font-size: 20px;
		padding: 0 0 20px 12%;
		box-sizing: border-box;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #ffffff;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		display: flex;
		align-items: center;
	}

	.con-l-title span {
		display: block;
		padding-bottom: 10px;
	}

	.con-l-title img {
		width: 12px;
		height: 12px;
		margin: 0 0 1% 3%;
	}

	/* 数据 */

	.scx progress {
		width: 100%;
		height: 10px;
		border-radius: 0;
	}

	.scx progress::-webkit-progress-bar {
		background: rgba(21, 154, 255, 0.2);
	}

	.scx progress::-webkit-progress-value {
		background: linear-gradient(90deg, #159aff 0%, #66e1df 100%);
	}

	.rkclsl div {
		width: 100%;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: space-between;
	}

	.rkclsl div span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #d0deee;
	}

	.rkclsl div p {
		font-size: 24px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #ffffff;
	}

	/*  */
	.clscsl {
		margin-top: 2%;
		margin-bottom: 10px;
	}

	.rkclsl .xl p {
		font-size: 20px;
	}

	.rkclsl .xl p span {
		font-size: 12px;
	}

	/* 出入库数据 */
	.crk-title-l {
		width: 130px;
	}

	.crk-title-l {
		display: flex;
		align-items: center;
	}

	.crk-title {
		display: flex;
		justify-content: space-between;
		margin: 40px 0 20px 0;
	}

	.crk-tab {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.crk-tab span {
		width: 50px;
		height: 25px;
		display: block;
		border-top: 2px solid #6c8097;
		background: linear-gradient(180deg, #2d3f57 0%, rgba(108, 128, 151, 0) 100%);
		font-size: 14px;
		line-height: 25px;
		text-align: center;
		margin-left: 6px;
	}

	.crk-tab .on {
		border-top: 2px solid #159aff;
		background: linear-gradient(180deg, #094983 0%, rgba(21, 154, 255, 0) 100%);
	}

	.crkdata-top {
		display: flex;
		justify-content: space-between;
	}

	.crkdata-top-item {
		width: 165px;
		height: 68px;
		background: linear-gradient(90deg, rgba(21, 154, 255, 0) 0%, rgba(3, 26, 62) 31%, rgba(3, 26, 62) 69%, rgba(21, 154, 255, 0) 100%);
		border-left: 2px solid #159aff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.crkdata-top-item span {
		color: #d0deee;
		font-size: 14px;
	}

	.crkdata-top-item p {
		color: #fff;
		font-weight: 600;
		font-size: 24px;
	}

	/* 今日成品料出厂 */
	.jrcpl {
		display: flex;
		align-items: center;
	}

	.jrcpl-item .jrcpl-item-con {
		display: flex;
		align-items: center;
	}

	.jrcpl-list {
		width: 65%;
		height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.jrcpl-list-item {
		display: flex;
		align-items: flex-end;
	}

	.jrcpl-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(108, 128, 151, 0.5);
	}

	.jrcpl-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #d0deee;
	}

	.jrcpl-item p {
		font-size: 18px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #ffffff;
	}

	.jrcpl-item div div {
		width: 10px;
		height: 10px;
		background: #159aff;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		margin-right: 10px;
	}

	.dian {
		width: 4px;
		height: 4px;
		background: #6c8097;
		border-radius: 100%;
	}

	.jrcpl-item div .cpl2 {
		background: #d0deee;
	}

	.jrcpl-item div .cpl3 {
		background: #66e1df;
	}

	/* 中间内容 监控 */
	.con-center2 {
		width: 968px;
		height: 775px;
		padding: 20px;
		box-sizing: border-box;
		background: url(../../assets/images/jkbg.png) no-repeat;
	}

	.con-center-main-top {
		width: 100%;
		background: rgba(0, 111, 195, 0.1);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.con-center-tab {
		display: flex;
		align-items: center;
	}

	.jk-select {
		background: linear-gradient(360deg, rgba(21, 154, 255, 0.5) 0%, rgba(21, 154, 255, 0) 100%);
		border-bottom: 4px solid #66e1df;
		/* background: linear-gradient(90deg, #159AFF 0%, #66E1DF 100%); */
	}

	.con-center-tab span {
		display: block;
		width: 168px;
		height: 58px;
		line-height: 58px;
		text-align: center;
		font-size: 20px;
		color: #ffffff;
	}

	.con-center-sx {
		box-sizing: border-box;
		width: 100%;
		height: 80px;
		background: rgba(0, 111, 195, 0.2);
		display: flex;
	}

	.On-access {
		padding: 20px;
		box-sizing: border-box;
		width: 100%;
		height: 80px;
		background: rgba(0, 111, 195, 0.2);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.con-center-sx-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.con-center-sx-l {
		width: 50%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.con-center-sx-l .con-center-sx-jk {
		width: 190px;
	}

	.jklb {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 20px;
		color: #ffffff;
	}

	.jklb img {
		margin-right: 10px;
	}

	.search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 276px;
		height: 40px;
		background: rgba(0, 111, 195, 0.1);
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}

	.search input {
		border: none;
		height: 100%;
		flex: 1;
		background-color: rgba(0, 0, 0, 0);
		font-size: 16px;
		color: #ffffff;
		padding-left: 1em;
		outline: none;
	}

	.search input::placeholder {
		color: #fff;
	}

	.con-center-sx-btm {
		z-index: 99;
		background: #000f28;
		border: 1px solid #66e1df;
		margin-top: 20px;
		padding: 20px 0px 20px 14px;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
	}

	.con-center-sx-btm .active {
		border: 1px solid #66e1df;
		color: #66e1df;
	}

	.con-center-sx-btm .con-center-sx-jk img {
		width: 20px;
		height: 20px;
	}

	.con-center-sx-jk {
		padding: 0 10px;
		box-sizing: border-box;
		width: 276px;
		height: 44px;
		opacity: 1;
		border: 1px solid rgba(208, 222, 238, 0.5);
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		color: #d0deee;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.con-center-sx-jk p {
		display: flex;
		align-items: center;
	}

	/* 录像回放 */
	.con-center-sx-r {
		width: 50%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background-color: #001e3f;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.lx-rq-search {
		display: flex;
		justify-content: space-between;
	}

	.lx-search {
		display: flex;
		align-items: center;
		font-size: 20px;
		color: #ffffff;
	}

	.lx-search img {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}

	.lx-rq {
		display: flex;
		align-items: center;
	}

	.lx-rq span {
		font-size: 18px;
		color: #d0deee;
	}

	.el-input::placeholder {
		color: #fff;
	}

	.el-input__inner {
		background: #011633;
		border: 1px solid;
		color: #fff;
		box-sizing: border-box;
		border-image: linear-gradient(134deg, rgba(21, 154, 255, 1), rgba(102, 225, 223, 1)) 1 1;
	}

	.el-picker-panel,
	.el-range-input,
	.el-picker-panel__footer,
	.el-time-panel__content {
		background: #011633;
		color: #fff;
	}

	.el-time-panel__footer {
		background: #011633;
	}

	.el-time-spinner__item.active {
		color: #fff !important;
	}

	.el-time-spinner__item {
		color: #66e1df;
	}

	.el-picker-panel__link-btn {
		background: none;
		color: #fff;
	}

	.el-time-panel__btn,
	.el-range-separator,
	.el-date-editor .el-range-input,
	.el-date-editor .el-range-separator {
		color: #fff;
	}

	.el-date-table__row .available.in-range {
		background: #000 ! !important;
	}

	.lx-rq .el-date-editor {
		width: 364px;
		box-sizing: border-box;
	}

	.lx-sou {
		width: 60px;
		height: 40px;
		line-height: 38px;
		text-align: center;
		font-size: 16px;
		color: #ffffff;
		background: linear-gradient(180deg, #159aff 0%, rgba(21, 154, 255, 0.5) 100%);
	}

	/* 监控画面 */
	.jkhm {
		width: 100%;
		height: 520px;
		position: relative;
	}

	.jkhm img {
		display: block;
		width: 100%;
		height: 520px;
	}

	.jkhm .jkhm-top {
		box-sizing: border-box;
		width: 100%;
		height: 68px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: absolute;
		top: 0;
	}

	.jkhm .jkhm-top p {
		font-size: 20px;
		color: #ffffff;
	}

	.jkhm .jkhm-top span {
		margin-left: 20px;
		font-size: 24px;
		color: #66e1df;
	}

	/* you */
	.con-r {
		width: 400px;
		padding-right: 36px;
		box-sizing: border-box;
	}

	/* 安全预警 */
	.aqjg {
		width: 100%;
		display: flex;
		justify-content: space-around;
		margin-bottom: 40px;
	}

	.aqjg img {
		width: 76px;
		height: 92px;
	}

	/* 人力资源 */
	.rlzy-title {
		margin: 0px 0 20px 0;
	}

	.rlzy-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 40px;
	}

	.rlzy-item {
		width: 335px;
		margin-bottom: 16px;
		padding: 10px 20px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(21, 154, 255, 0.1);
	}

	.rlzy-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #d0deee;
	}

	.rlzy-item .rlzy-l {
		display: flex;
		align-items: center;
	}

	.rlzy-item .rlzy-l img {
		display: block;
		margin-right: 10px;
		width: 42px;
		height: 42px;
	}

	.rlzy-item .rlzy-l div {
		width: 2px;
		height: 20px;
		background: #66e1df;
		margin-right: 10px;
	}

	.rlzy-item p {
		font-size: 20px;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #ffffff;
	}

	/* 电力资源 */

	.dlzy-top {
		display: flex;
		justify-content: space-between;
	}

	.dlzy-item {
		display: flex;
		align-items: center;
	}

	.dlzy-item span {
		font-size: 12px;
		color: #d0deee;
	}

	.dlzy-item p {
		font-size: 24px;
		color: #fff;
	}

	#line {
		width: 380px;
		height: 300px;
		padding-left: 30px;
	}

	/* 地磅实时数据 */
	.con-left .title {
		/* display: block; */
		height: 40px;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		padding: 0 0 20px 50px;
		margin-left: 1%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
	}

	.con-left .title span {
		display: block;
		margin-bottom: 10px;
	}

	.con-left .title img {
		display: block;
		width: 12px;
		height: 12px;
		margin-left: 10px;
	}

	/* dibang */
	.con-left-buttom {
		padding-left: 36px;
		box-sizing: border-box;

		.clb_tab {
			margin-bottom: 20px;
			display: flex;
			align-items: center;

			.son {
				padding: 0 14px;
				height: 40px;
				display: block;
				border-top: 2px solid #6c8097;
				background: linear-gradient(180deg, #2d3f57 0%, rgba(108, 128, 151, 0) 100%);
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				line-height: 32px;
				text-align: center;
				margin-right: 10px;

				&.on {
					border-top: 2px solid #159aff;
					background: linear-gradient(180deg, #094983 0%, rgba(21, 154, 255, 0) 100%);
				}
			}
		}
	}

	/* rili */
	.rl-box {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.1);
		padding-top: 0px;
		box-sizing: border-box;
		top: 0px;
		left: 0px;
		position: absolute;
		z-index: 9;
		display: flex;
		justify-content: flex-start;
	}

	.rl-bg {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		z-index: 10;
		background: rgba(0, 0, 0, 0.1);
	}

	.rl-con {
		position: absolute;
		top: 346px;
		left: 520px;
		z-index: 10;
		padding-left: 520px;
		box-sizing: border-box;
	}

	.db-t {
		width: 100%;
		color: #d0deee;
		text-align: center;
		display: flex;
		align-items: center;
	}

	.db-t-h {
		width: 6vw;
		font-size: 14px;
	}

	/* */
	.db-t-h:nth-last-child(1),
	.db-t-h:nth-last-child(2) {
		width: 160px;
	}

	.db-t-h:nth-child(1) {
		width: 4vw;
	}

	.db-b {
		font-size: 13px;
		color: #fff;
		padding: 4px 0;
	}

	.db-b div {
		display: flex;
		align-items: center;
	}

	.db-b div span:nth-child(1) {
		width: 5vw;
	}

	.db-b div span {
		width: 8vw;
		display: block;
		text-align: center;
	}

	.db-b div span input {
		width: 70px;
		border: none;
		outline: none;
		margin: 0 4px 0 0;
		background: #102b42;
		color: #fff;
		padding-left: 2px;
	}

	.jkhm .el-date-picker {
		width: 200px;
	}

	.el-date-picker__header-label,
	.el-picker-panel__icon-btn {
		color: #fff !important;
	}

	.el-picker-panel,
	.el-year-table a,
	.el-month-table a {
		background: #011633;
		color: #fff !important;
	}

	.editname {
		display: flex;
		align-items: center;
		font-size: 18px;
	}

	.editname input {
		outline: none;
		padding: 10px 2em;
		margin-left: 20px;
	}

	.el-date-table td.in-range div {
		background: #2b73f9;
	}

	/* 遮罩层 */
	.zhezhao {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
	}

	/*  */
	.jrcpl-item .jrcpl-item-con .kuai1 {
		background-color: #d0deee;
	}

	.jrcpl-item .jrcpl-item-con .kuai2 {
		background-color: #66e1df;
	}

	#pieone {
		width: 200px;
		height: 200px;
	}

	#pietwo {
		width: 200px;
		height: 200px;
	}

	#piethree {
		width: 200px;
		height: 200px;
	}

	.renedit {
		color: #fff;
		margin: 0 auto;
		text-align: center;
		font-size: 40px;
		margin-top: 30px;
	}

	.ren-item {
		color: #fff;
		margin-bottom: 20px;
	}

	.ren-item label {
		margin-right: 10px;
		display: inline-block;
		width: 80px;
		height: 30px;
		line-height: 30px;
		vertical-align: middle;
		text-align: justify;
		text-align-last: justify;
	}

	label::after {
		content: ' ';
		display: inline-block;
		width: 100%;
	}

	.ren-item input {
		outline: none;
		padding: 10px 2em;
		background: #102b42;
		border: none;
		color: #fff;
	}

	.renren {
		position: relative;
		width: 40vw !important;
		height: 60% !important;
	}

	.ren-box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
	}

	.ren-btn {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 30px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.ren-btn div {
		color: #66e1df;
		font-size: 24px;
	}

	.zh-box {
		width: 65%;
		height: 720px;
		background: #021328;
		opacity: 1;
		border: 1px solid;
		box-sizing: border-box;
		border-image: linear-gradient(153deg, rgba(102, 225, 223, 1), rgba(102, 225, 223, 0)) 2 2;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
	}

	.sp {
		z-index: -1;
	}
</style>